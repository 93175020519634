import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CraftingRite from './components/crafting-rite';
import './crafting.css';

export default class Crafting extends Component {
    constructor(props) {
        super(props);
        this.state = { craftingRites: [], loading: true, selectedRite: "", blessingTexts: [] };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.getCraftingRites();
    }

    renderRiteNamesDropdown(craftingRites) {
        return (
            <Form.Select
                className="rite-list"
                value={this.state.selectedRite}
                onChange={this.handleChange}
            >
                <option
                    disabled
                    value=""
                    key=""
                >
                    select rite
                </option>
                {craftingRites.map(craftingRite =>
                    <option
                        value={craftingRite.riteName}
                        key={craftingRite.riteName}
                    >
                        {craftingRite.riteName}
                    </option>
                )}
            </Form.Select>
        );
    }

    renderCraftingRite(craftingRite) {
        return (
            <CraftingRite craftingRite={craftingRite} />
        );
    }

    renderBlessingTexts(blessingTexts) {
        return (
            <div>
                <h2>Item Boosts</h2>
                {blessingTexts.map(blessingText =>
                    <div>
                        <h3>{blessingText.title}</h3>
                        <p className="power-quote">{blessingText.abilityText}</p>
                        {blessingText.guidance.map(guidance =>
                            <p>{guidance}</p>
                        )}
                    </div>
                )}
            </div>
        );
    }

    render() {
        let riteNamesDropdown = this.state.loading
            ? <p>Loading crafting rites...</p>
            : this.renderRiteNamesDropdown(this.state.craftingRites)

        let selectedCraftingRite = this.state.loading || this.state.selectedRite === ""
            ? <p></p>
            : this.renderCraftingRite(this.state.craftingRites.find(cr => cr.riteName === this.state.selectedRite))

        let blessingTexts = this.renderBlessingTexts(this.state.blessingTexts)

        return (
            <div>
                <Row>
                    <Col>
                        <h1>Crafting</h1>
                        <Form.Group
                            className="rite-selection"
                        >
                            <Form.Label>Crafting Rite: </Form.Label>
                            {riteNamesDropdown}
                        </Form.Group>
                        {selectedCraftingRite}
                    </Col>
                    <Col xs={3}>
                        {blessingTexts}
                    </Col>
                </Row>
            </div>
        );
    }

    async getCraftingRites() {
        const response = await fetch("crafting/getcraftingrites");
        const data = await response.json();

        const blessingResponse = await fetch("crafting/GetBlessingTexts");
        const blessingData = await blessingResponse.json();

        this.setState({ craftingRites: data, loading: false, selectedRite: "", blessingTexts: blessingData });
    }

    handleChange(e) {
        this.setState({ selectedRite: e.target.value });
    }
}
