import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import './lammie.css';

export default class SecondLammie extends Component {
    constructor(props) {
        super(props);
        this.state = {
            printListSize: 0,
        };

        this.addToLammiePrintList = this.addToLammiePrintList.bind(this);
    }

    componentDidMount() {
        this.getPrintListSize();
    }

    render() {
        const fontSizeLeft = {
            fontSize: this.props.fontSize.left + "px"
        }

        const fontSizeRight = {
            fontSize: this.props.fontSize.right + "px"
        }

        return (
            <div>
                <table className="lammie">
                    <tbody>
                        <tr>
                            <td className="lammie-data lammie-data-left" style={fontSizeLeft}>
                                {this.props.magicItem.lammieText}
                            </td>
                            <td className="lammie-data lammie-data-left" style={fontSizeRight}>
                                {this.props.magicItem.thirdEye.glance}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <div className="mb-3">
                    <Button className="d-inline" variant="primary" onClick={this.addToLammiePrintList}>Add to print list</Button>{' '}
                    <div className="d-inline">Items in print list: {this.state.printListSize}</div>
                </div>
            </div>
        )
    }

    async getPrintListSize() {
        const response = await fetch("print/getlammieprintlistsize");
        const data = await response.json();
        this.setState({ printListSize: data });
    }

    async addToLammiePrintList() {
        const printableMagicItem = {
            lammieTitle: this.props.magicItem.lammieTitle,
            lammieText: this.props.magicItem.lammieText,
            thirdEye: this.props.magicItem.thirdEye,
            fontSizeLeft: this.props.fontSize.left,
            fontSizeRight: this.props.fontSize.right
        }

        const settings = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(printableMagicItem)
        };

        const response = await fetch("print/addtolammieprintlist", settings);
        const data = await response.json();
        this.setState({ printListSize: data });
    }
}
