import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import WiseCard from './components/wise-card';
import "./wise.css";

export default class WiseCards extends Component {
    constructor(props) {
        super(props);
        this.state = {
            wiseCards: [],
            loading: true,
            selectedWiseCard: "",
            wiseCard: { wiseCardTitle: "", description: "", condition: "", hiddenText: "" },
            fontSize: { left: "14", right: "14" }
        };

        this.handleWiseCardSelection = this.handleWiseCardSelection.bind(this);
        this.handleWiseCardTitleChange = this.handleWiseCardTitleChange.bind(this);
        this.handleWiseCardDescriptionChange = this.handleWiseCardDescriptionChange.bind(this);
        this.handleWiseCardConditionChange = this.handleWiseCardConditionChange.bind(this);
        this.handleWiseCardHiddenTextChange = this.handleWiseCardHiddenTextChange.bind(this);
        this.handleLeftFontSizeChange = this.handleLeftFontSizeChange.bind(this);
        this.handleRightFontSizeChange = this.handleRightFontSizeChange.bind(this);
    }

    componentDidMount() {
        this.getWiseCards();
    }

    renderWiseCardsDropdown(wiseCards) {
        return (
            <Form.Select
                className="wise-cards-list"
                value={this.state.selectedWiseCard}
                onChange={this.handleWiseCardSelection}
            >
                <option
                    disabled
                    value=""
                    key=""
                >
                    select wise card
                </option>
                {wiseCards.map(wiseCard =>
                    <option
                        value={wiseCard.wiseCardTitle}
                        key={wiseCard.wiseCardTitle}
                    >
                        {wiseCard.wiseCardTitle}
                    </option>
                )}
            </Form.Select>
        );
    }

    render() {
        let wiseCardsDropdown = this.state.loading
            ? <p>Loading lammies...</p>
            : this.renderWiseCardsDropdown(this.state.wiseCards);

        return (
            <div>
                <h1>Wise Cards</h1>
                <Form.Group
                    className="wise-card-selection"
                >
                    <Form.Label>Wise Card: </Form.Label>
                    {wiseCardsDropdown}
                </Form.Group>
                <Form.Group className="mb-3 w-50">
                    <Form.Label>Wise Card Title</Form.Label>
                    <Form.Control
                        type="text"
                        value={this.state.wiseCard.wiseCardTitle}
                        onChange={this.handleWiseCardTitleChange} />
                </Form.Group>
                <Form.Group className="mb-3 w-50">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={5}
                        value={this.state.wiseCard.description}
                        onChange={this.handleWiseCardDescriptionChange}
                    />
                </Form.Group>
                <Form.Group className="mb-3 w-50">
                    <Form.Label>Condition</Form.Label>
                    <Form.Control
                        type="text"
                        value={this.state.wiseCard.condition}
                        onChange={this.handleWiseCardConditionChange}
                    />
                </Form.Group>
                <Form.Group className="mb-3 w-50">
                    <Form.Label>Hidden Text</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={5}
                        value={this.state.wiseCard.hiddenText}
                        onChange={this.handleWiseCardHiddenTextChange}
                    />
                </Form.Group>
                <div className="font-selection">
                    <Form.Group className="mb-3 w-25">
                        <Form.Label>Font Size Front</Form.Label>
                        <Form.Select
                            value={this.state.fontSize.left}
                            onChange={this.handleLeftFontSizeChange}
                        >
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="13">13</option>
                            <option value="14">14</option>
                            <option value="15">15</option>
                            <option value="16">16</option>
                            <option value="17">17</option>
                            <option value="18">18</option>
                            <option value="19">19</option>
                            <option value="20">20</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3 w-25">
                        <Form.Label>Font Size Back</Form.Label>
                        <Form.Select
                            value={this.state.fontSize.right}
                            onChange={this.handleRightFontSizeChange}
                        >
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="13">13</option>
                            <option value="14">14</option>
                            <option value="15">15</option>
                            <option value="16">16</option>
                            <option value="17">17</option>
                            <option value="18">18</option>
                            <option value="19">19</option>
                            <option value="20">20</option>
                        </Form.Select>
                    </Form.Group>
                </div>
                <WiseCard wiseCard={this.state.wiseCard} fontSize={this.state.fontSize} />
            </div>
        );
    }

    async getWiseCards() {
        const response = await fetch("wise/getwisecards");
        const data = await response.json();
        this.setState({ wiseCards: data, loading: false });
    }

    handleWiseCardSelection(e) {
        this.setState({ selectedWiseCard: e.target.value });
        this.setState({ wiseCard: this.state.wiseCards.find(wiseCard => wiseCard.wiseCardTitle === e.target.value) })
    }

    handleWiseCardTitleChange(e) {
        this.setState({ wiseCard: { wiseCardTitle: e.target.value, description: this.state.wiseCard.description, condition: this.state.wiseCard.condition, hiddenText: this.state.wiseCard.hiddenText } });
    }

    handleWiseCardDescriptionChange(e) {
        this.setState({ wiseCard: { wiseCardTitle: this.state.wiseCard.wiseCardTitle, description: e.target.value, condition: this.state.wiseCard.condition, hiddenText: this.state.wiseCard.hiddenText } });
    }

    handleWiseCardConditionChange(e) {
        this.setState({ wiseCard: { wiseCardTitle: this.state.wiseCard.wiseCardTitle, description: this.state.wiseCard.description, condition: e.target.value, hiddenText: this.state.wiseCard.hiddenText } });
    }

    handleWiseCardHiddenTextChange(e) {
        this.setState({ wiseCard: { wiseCardTitle: this.state.wiseCard.wiseCardTitle, description: this.state.wiseCard.description, condition: this.state.wiseCard.condition, hiddenText: e.target.value } });
    }

    handleLeftFontSizeChange(e) {
        this.setState({ fontSize: { left: e.target.value, right: this.state.fontSize.right } });
    }

    handleRightFontSizeChange(e) {
        this.setState({ fontSize: { left: this.state.fontSize.left, right: e.target.value } });
    }
}
