import React, { Component } from 'react';
import './components/lammie.css';

export default class PrintingLammies extends Component {
    constructor(props) {
        super(props);
        this.state = { lammies: [] };
    }

    componentDidMount() {
        this.getLammies();
    }

    renderGaze(gaze) {
        if (gaze) {
            return (
                <div>
                    <br />
                    <b>Gaze:</b> {gaze}
                </div>
            )
        } else {
            return (
                <div></div>
            )
        }
    }

    renderLammie(lammie, index) {
        const fontSizeLeft = {
            fontSize: lammie.fontSizeLeft + "px"
        };

        const fontSizeRight = {
            fontSize: lammie.fontSizeRight + "px"
        };

        if (lammie.lammieTitle.endsWith(" (2)")) {
            return (
                <tr
                    key={index}
                >
                    <td className="lammie-data lammie-data-left" style={fontSizeLeft}>
                        {lammie.lammieText}
                    </td>
                    <td className="lammie-data lammie-data-left" style={fontSizeRight}>
                        {lammie.thirdEye.glance}
                    </td>
                </tr>
            );
        } else {
            return (
                <tr
                    key={index}
                >
                    <td className="lammie-data lammie-data-left" style={fontSizeLeft}>
                        <b>{lammie.lammieTitle}</b>
                        <br />
                        {lammie.lammieText}
                    </td>
                    <td className="lammie-data lammie-data-right" style={fontSizeRight}>
                        <b>Glance:</b> {lammie.thirdEye.glance}
                        {this.renderGaze(lammie.thirdEye.gaze)}
                    </td>
                </tr>
            );
        }
    }

    render() {
        return (
            <div>
                <table className="lammie">
                    <tbody>
                        {this.state.lammies.map((lammie, index) =>
                            this.renderLammie(lammie, index)
                        )}
                    </tbody>
                </table>
            </div>
        );
    }

    async getLammies() {
        const response = await fetch("print/getlammieprintlist");
        const data = await response.json();
        this.setState({ lammies: data });
    }
}
