import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Lammie from './components/lammie';
import SecondLammie from './components/second-lammie';
import './crafting.css';

export default class INeedALammie extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lammies: [],
            loading: true,
            selectedLammie: "",
            magicItem: { lammieId: "", lammieTitle: "", lammieText: "", thirdEye: { glance: "", gaze: "" } },
            fontSize: { left: "14", right: "14" }
        };

        this.renderLammieEditor = this.renderLammieEditor.bind(this);
        this.handleLammieSelection = this.handleLammieSelection.bind(this);
        this.handleLammieTitleChange = this.handleLammieTitleChange.bind(this);
        this.handleLammieTextChange = this.handleLammieTextChange.bind(this);
        this.handleGlanceChange = this.handleGlanceChange.bind(this);
        this.handleGazeChange = this.handleGazeChange.bind(this);
        this.handleLeftFontSizeChange = this.handleLeftFontSizeChange.bind(this);
        this.handleRightFontSizeChange = this.handleRightFontSizeChange.bind(this);
    }

    componentDidMount() {
        this.getLammies();
    }

    renderLammieEditor() {
        if (this.state.magicItem.lammieTitle.endsWith(" (2)")) {
            return (
                <SecondLammie magicItem={this.state.magicItem} fontSize={this.state.fontSize} />
            )
        }
        else {
            return (
                <Lammie magicItem={this.state.magicItem} fontSize={this.state.fontSize} />
            )
        }
    }

    renderLammieNamesDropdown(lammies) {
        return (
            <Form.Select
                className="rite-list"
                value={this.state.selectedLammie}
                onChange={this.handleLammieSelection}
            >
                <option
                    disabled
                    value=""
                    key=""
                >
                    select lammie
                </option>
                {lammies.map(lammie =>
                    <option
                        value={lammie.lammieId}
                        key={lammie.lammieId}
                    >
                        {lammie.lammieId}
                    </option>
                )}
            </Form.Select>
        );
    }

    render() {
        let lammieNamesDropdown = this.state.loading
            ? <p>Loading lammies...</p>
            : this.renderLammieNamesDropdown(this.state.lammies);

        return (
            <div>
                <h1>Lammies</h1>
                <Form.Group
                    className="rite-selection"
                >
                    <Form.Label>Lammie: </Form.Label>
                    {lammieNamesDropdown}
                </Form.Group>
                <div className="mb-3">
                    <Form.Group className="mb-3 w-50">
                        <Form.Label>Lammie Title</Form.Label>
                        <Form.Control
                            type="text"
                            value={this.state.magicItem.lammieTitle}
                            onChange={this.handleLammieTitleChange} />
                    </Form.Group>
                    <Form.Group className="mb-3 w-50">
                        <Form.Label>Lammie Text</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={5}
                            value={this.state.magicItem.lammieText}
                            onChange={this.handleLammieTextChange}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3 w-50">
                        <Form.Label>Glance</Form.Label>
                        <Form.Control
                            type="text"
                            value={this.state.magicItem.thirdEye.glance}
                            onChange={this.handleGlanceChange}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3 w-50">
                        <Form.Label>Gaze</Form.Label>
                        <Form.Control
                            type="text"
                            value={this.state.magicItem.thirdEye.gaze}
                            onChange={this.handleGazeChange}
                        />
                    </Form.Group>
                    <div className="font-selection">
                        <Form.Group className="mb-3 w-25">
                            <Form.Label>Font Size Front</Form.Label>
                            <Form.Select
                                value={this.state.fontSize.left}
                                onChange={this.handleLeftFontSizeChange}
                            >
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                                <option value="18">18</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3 w-25">
                            <Form.Label>Font Size Back</Form.Label>
                            <Form.Select
                                value={this.state.fontSize.right}
                                onChange={this.handleRightFontSizeChange}
                            >
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                                <option value="18">18</option>
                            </Form.Select>
                        </Form.Group>
                    </div>
                </div>
                {this.renderLammieEditor()}
            </div>
        );
    }

    async getLammies() {
        const response = await fetch("lammie/getlammies");
        const data = await response.json();
        this.setState({ lammies: data, loading: false });
    }

    handleLammieSelection(e) {
        this.setState({ selectedLammie: e.target.value });
        this.setState({ magicItem: this.state.lammies.find(lammie => lammie.lammieId === e.target.value) })
    }

    handleLammieTitleChange(e) {
        this.setState({ magicItem: { lammieTitle: e.target.value, lammieText: this.state.magicItem.lammieText, thirdEye: this.state.magicItem.thirdEye } });
    }

    handleLammieTextChange(e) {
        this.setState({ magicItem: { lammieTitle: this.state.magicItem.lammieTitle, lammieText: e.target.value, thirdEye: this.state.magicItem.thirdEye } });
    }

    handleGlanceChange(e) {
        this.setState({ magicItem: { lammieTitle: this.state.magicItem.lammieTitle, lammieText: this.state.magicItem.lammieText, thirdEye: { glance: e.target.value, gaze: this.state.magicItem.thirdEye.gaze } } });
    }

    handleGazeChange(e) {
        this.setState({ magicItem: { lammieTitle: this.state.magicItem.lammieTitle, lammieText: this.state.magicItem.lammieText, thirdEye: { glance: this.state.magicItem.thirdEye.glance, gaze: e.target.value } } });
    }

    handleLeftFontSizeChange(e) {
        this.setState({ fontSize: { left: e.target.value, right: this.state.fontSize.right } });
    }

    handleRightFontSizeChange(e) {
        this.setState({ fontSize: { left: this.state.fontSize.left, right: e.target.value } });
    }
}
