import React, { Component } from 'react';

export default class Colour extends Component {
    render() {
        if (this.props.amount > 0) {
            return (
                <span className="colour">
                    <b>{this.props.colour}:</b> {this.props.amount}
                </span>
            )
        }
        else {
            return (
                <span></span>
            )
        }
    }
}
