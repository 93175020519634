import React, { Component } from 'react';
import './components/wise-card.css';

export default class PrintingWises extends Component {
    constructor(props) {
        super(props);
        this.state = { wiseCards: [] };
    }

    componentDidMount() {
        this.getWiseCards();
    }

    renderWiseCard(wiseCard, index) {
        const styleLeft = {
            fontSize: wiseCard.fontSizeLeft + "px"
        };

        let largeFont = parseInt(wiseCard.fontSizeLeft) + 3;
        const styleLeftLarge = {
            fontSize: largeFont + "px"
        };

        const styleRight = {
            fontSize: wiseCard.fontSizeRight + "px"
        };

        return (
            <tr
                key={index}
            >
                <td className="wise-card-data wise-card-data-left">
                    <div style={styleLeft}>{wiseCard.description}</div>
                    <br />
                    <div className="wise-condition" style={styleLeftLarge}>{wiseCard.condition}</div>
                </td>
                <td className="wise-card-data wise-card-data-right" style={styleRight} dangerouslySetInnerHTML={{ __html: wiseCard.hiddenText }}>
                </td>
            </tr>
        );
    }

    render() {
        return (
            <div>
                <table className="wise-card">
                    <tbody>
                        {this.state.wiseCards.map((wiseCard, index) =>
                            this.renderWiseCard(wiseCard, index)
                        )}
                    </tbody>
                </table>
            </div>
        );
    }

    async getWiseCards() {
        const response = await fetch("print/getwisecardprintlist");
        const data = await response.json();
        this.setState({ wiseCards: data });
    }
}
