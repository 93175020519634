import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import "./manage-wise-printing.css";

export default class ManageWisePrinting extends Component {
    constructor(props) {
        super(props);
        this.state = { wiseCards: [] };

        this.clearPrintList = this.clearPrintList.bind(this);
    }

    componentDidMount() {
        this.getWiseCards();
    }

    render() {
        return (
            <div>
                <Button variant="danger" onClick={this.clearPrintList}>Clear Print List</Button>
                <br />
                <br />
                <ul className="wise-card-print-list">
                    {this.state.wiseCards.map((wiseCard, index) =>
                        <li
                            key={index}
                        >
                            <div className="d-inline wise-card-print-title">{wiseCard.wiseCardTitle}</div>
                            <div className="d-inline">
                                <Button className="wise-card-print-remove" onClick={() => this.removeFromPrintList(index)} variant="danger">-</Button>
                            </div>
                        </li>
                    )}
                </ul>
            </div>
        );
    }

    async getWiseCards() {
        const response = await fetch("print/getwisecardprintlist");
        const data = await response.json();
        this.setState({ wiseCards: data });
    }

    async clearPrintList() {
        const settings = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        };

        await fetch("print/clearwisecardprintlist", settings);
        this.setState({ wiseCards: [] });
    }

    async removeFromPrintList(index) {
        const settings = {
            method: "POST"
        };

        await fetch("print/removefromwisecardprintlist?listPosition=" + index, settings);

        this.getWiseCards();
    }
}
