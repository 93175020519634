import { Route, Routes, Link, useSearchParams } from "react-router-dom";
import Crafting from "./pages/crafting";
import INeedALammie from "./pages/i-need-a-lammie";
import PrintingLammies from "./pages/printing-lammies";
import ManageLammiePrinting from "./pages/manage-lammie-printing";
import "./App.css";
import 'bootstrap/dist/css/bootstrap.css';
import WiseCards from "./pages/wise-cards";
import ManageWisePrinting from "./pages/manage-wise-printing";
import PrintingWises from "./pages/printing-wises";
import Authorization from "./pages/authorization";

export default function App() {
    const [searchParams] = useSearchParams();

    const hideNav = searchParams.get("hidenav");

    return (
        <>
            {!hideNav &&
                <nav>
                    <ul>
                        <li><Link to="/crafting-page" className="link">Crafting</Link></li>
                        <li><Link to="/i-need-a-lammie" className="link">I Need A Lammie!</Link></li>
                        <li><Link to="/manage-lammie-printing" className="link">Manage Lammie Printing</Link></li>
                        <li><Link to="/view-print-list?hidenav=true" className="link">Print Lammies</Link></li>
                        <li><Link to="/path-wise-cards" className="link">Wise Cards</Link></li>
                        <li><Link to="/manage-wise-printing" className="link">Manage Wise Printing</Link></li>
                        <li><Link to="/view-wise-print-list?hidenav=true" className="link">Print Wises</Link></li>
                    </ul>
                </nav>
            }
            <div className="main-content">
                <Routes>
                    <Route path="/" element={<Authorization />} />
                    <Route path="/crafting-page" element={<Crafting />} />
                    <Route path="/i-need-a-lammie" element={<INeedALammie />} />
                    <Route path="/manage-lammie-printing" element={<ManageLammiePrinting />} />
                    <Route path="/view-print-list" element={<PrintingLammies />} />
                    <Route path="/path-wise-cards" element={<WiseCards />} />
                    <Route path="/manage-wise-printing" element={<ManageWisePrinting />} />
                    <Route path="/view-wise-print-list" element={<PrintingWises />} />
                </Routes>
            </div>
        </>
    )
}
