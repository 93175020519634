import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import "./wise-card.css";

export default class WiseCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            printListSize: 0,
        };

        this.addToWiseCardPrintList = this.addToWiseCardPrintList.bind(this);
    }

    componentDidMount() {
        this.getPrintListSize();
    }

    render() {
        const styleLeft = {
            fontSize: this.props.fontSize.left + "px"
        };

        let largeFont = parseInt(this.props.fontSize.left) + 3;
        const styleLeftLarge = {
            fontSize: largeFont + "px"
        };

        const styleRight = {
            fontSize: this.props.fontSize.right + "px"
        };

        return (
            <div>
                <table className="wise-card">
                    <tbody>
                        <tr>
                            <td className="wise-card-data wise-card-data-left">
                                <div style={styleLeft}>{this.props.wiseCard.description}</div>
                                <br />
                                <div className="wise-condition" style={styleLeftLarge}>{this.props.wiseCard.condition}</div>
                            </td>
                            <td className="wise-card-data wise-card-data-right" style={styleRight} dangerouslySetInnerHTML={{ __html: this.props.wiseCard.hiddenText }}>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <div className="mb-3">
                    <Button className="d-inline" variant="primary" onClick={this.addToWiseCardPrintList}>Add to print list</Button>{' '}
                    <div className="d-inline">Items in print list: {this.state.printListSize}</div>
                </div>
            </div>
        )
    }

    async getPrintListSize() {
        const response = await fetch("print/getwisecardprintlistsize");
        const data = await response.json();
        this.setState({ printListSize: data });
    }

    async addToWiseCardPrintList() {
        const printableWiseCard = {
            wiseCardTitle: this.props.wiseCard.wiseCardTitle,
            description: this.props.wiseCard.description,
            condition: this.props.wiseCard.condition,
            hiddenText: this.props.wiseCard.hiddenText,
            fontSizeLeft: this.props.fontSize.left,
            fontSizeRight: this.props.fontSize.right
        }

        const settings = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(printableWiseCard)
        };

        const response = await fetch("print/addtowisecardprintlist", settings);
        const data = await response.json();
        this.setState({ printListSize: data });
    }
}
