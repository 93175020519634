import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import "./manage-lammie-printing.css";

export default class ManageLammiePrinting extends Component {
    constructor(props) {
        super(props);
        this.state = { lammies: [] };

        this.clearPrintList = this.clearPrintList.bind(this);
    }

    componentDidMount() {
        this.getLammies();
    }

    render() {
        return (
            <div>
                <Button variant="danger" onClick={this.clearPrintList}>Clear Print List</Button>
                <br />
                <br />
                <ul className="lammie-print-list">
                    {this.state.lammies.map((lammie, index) =>
                        <li
                            key={index}
                        >
                            <div className="d-inline lammie-print-title">{lammie.lammieTitle}</div>
                            <div className="d-inline">
                                <Button className="lammie-print-remove" onClick={() => this.removeFromPrintList(index)} variant="danger">-</Button>
                            </div>
                        </li>
                    )}
                </ul>
            </div>
        );
    }

    async getLammies() {
        const response = await fetch("print/getlammieprintlist");
        const data = await response.json();
        this.setState({ lammies: data });
    }

    async clearPrintList() {
        const settings = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        };

        await fetch("print/clearlammieprintlist", settings);
        this.setState({ lammies: [] });
    }

    async removeFromPrintList(index) {
        const settings = {
            method: "POST"
        };

        await fetch("print/removefromlammieprintlist?listPosition=" + index, settings);

        this.getLammies();
    }
}
