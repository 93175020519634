import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Colour from './colour';
import Button from 'react-bootstrap/Button';
import Lammie from './lammie';
import SecondLammie from './second-lammie';
import './lammie.css';

export default class CraftingRite extends Component {
    constructor(props) {
        super(props);
        this.state = {
            craftingResponseMessage: "",
            magicItem: { lammieTitle: "", lammieText: "", thirdEye: { glance: "", gaze: "" } },
            fontSize: { left: "14", right: "14" }
        };

        this.renderLammieEditor = this.renderLammieEditor.bind(this);
        this.submitCraftingRequest = this.submitCraftingRequest.bind(this);
        this.handleLammieTitleChange = this.handleLammieTitleChange.bind(this);
        this.handleLammieTextChange = this.handleLammieTextChange.bind(this);
        this.handleGlanceChange = this.handleGlanceChange.bind(this);
        this.handleGazeChange = this.handleGazeChange.bind(this);
        this.handleLeftFontSizeChange = this.handleLeftFontSizeChange.bind(this);
        this.handleRightFontSizeChange = this.handleRightFontSizeChange.bind(this);
    }

    renderLammieEditor() {
        if (this.state.magicItem.lammieTitle.endsWith(" (2)")) {
            return (
                <SecondLammie magicItem={this.state.magicItem} fontSize={this.state.fontSize} />
            )
        }
        else {
            return (
                <Lammie magicItem={this.state.magicItem} fontSize={this.state.fontSize} />
            )
        }
    }

    render() {
        return (
            <div>
                <h1>{this.props.craftingRite.riteName}</h1>
                <p>
                    <Colour colour="Black" amount={this.props.craftingRite.black} />
                    <Colour colour="Blue" amount={this.props.craftingRite.blue} />
                    <Colour colour="Green" amount={this.props.craftingRite.green} />
                    <Colour colour="Metallic" amount={this.props.craftingRite.metallic} />
                    <Colour colour="Orange" amount={this.props.craftingRite.orange} />
                    <Colour colour="Purple" amount={this.props.craftingRite.purple} />
                    <Colour colour="Red" amount={this.props.craftingRite.red} />
                    <Colour colour="White" amount={this.props.craftingRite.white} />
                    <Colour colour="Yellow" amount={this.props.craftingRite.yellow} />
                </p>
                {this.props.craftingRite.ingredientOptions.map((ingredientOption, index) =>
                    <Form.Select
                        className="ingredient-selection w-25 mb-3"
                        key={index}
                    >
                        <option
                            className="ingredient-option"
                            value=""
                            key=""
                        >
                            no ingredient
                        </option>
                        {ingredientOption.map(ingredient =>
                            <option
                                value={ingredient}
                                key={ingredient}
                            >
                                {ingredient}
                            </option>
                        )}
                    </Form.Select>
                )}
                <div className="mb-3">
                    <Button className="d-inline" variant="primary" onClick={this.submitCraftingRequest}>Submit Craft</Button>{' '}
                    <div className="d-inline text-danger">{this.state.craftingResponseMessage}</div>
                </div>
                <div className="mb-3">
                    <Form.Group className="mb-3 w-50">
                        <Form.Label>Lammie Title</Form.Label>
                        <Form.Control
                            type="text"
                            value={this.state.magicItem.lammieTitle}
                            onChange={this.handleLammieTitleChange} />
                    </Form.Group>
                    <Form.Group className="mb-3 w-50">
                        <Form.Label>Lammie Text</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={5}
                            value={this.state.magicItem.lammieText}
                            onChange={this.handleLammieTextChange}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3 w-50">
                        <Form.Label>Glance</Form.Label>
                        <Form.Control
                            type="text"
                            value={this.state.magicItem.thirdEye.glance}
                            onChange={this.handleGlanceChange}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3 w-50">
                        <Form.Label>Gaze</Form.Label>
                        <Form.Control
                            type="text"
                            value={this.state.magicItem.thirdEye.gaze}
                            onChange={this.handleGazeChange}
                        />
                    </Form.Group>
                    <div className="font-selection">
                        <Form.Group className="mb-3 w-25">
                            <Form.Label>Font Size Front</Form.Label>
                            <Form.Select
                                value={this.state.fontSize.left}
                                onChange={this.handleLeftFontSizeChange}
                            >
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                                <option value="18">18</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3 w-25">
                            <Form.Label>Font Size Back</Form.Label>
                            <Form.Select
                                value={this.state.fontSize.right}
                                onChange={this.handleRightFontSizeChange}
                            >
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                                <option value="18">18</option>
                            </Form.Select>
                        </Form.Group>
                    </div>
                </div>
                {this.renderLammieEditor()}
            </div>
        );
    }

    async submitCraftingRequest() {
        const ingredients = Array.from(document.getElementsByClassName('ingredient-selection')).map(option => option.value);

        const craftingRequest = {
            RiteName: this.props.craftingRite.riteName,
            IngredientNames: ingredients
        };

        const settings = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(craftingRequest)
        };

        const response = await fetch("crafting/submitCraftingRequest", settings);
        const data = await response.json();

        if (data.success) {
            this.setState({ craftingResponseMessage: data.message, magicItem: data.magicItem });
        } else {
            this.setState({ craftingResponseMessage: data.message })
        }
    }

    handleLammieTitleChange(e) {
        this.setState({ magicItem: { lammieTitle: e.target.value, lammieText: this.state.magicItem.lammieText, thirdEye: this.state.magicItem.thirdEye } });
    }

    handleLammieTextChange(e) {
        this.setState({ magicItem: { lammieTitle: this.state.magicItem.lammieTitle, lammieText: e.target.value, thirdEye: this.state.magicItem.thirdEye } });
    }

    handleGlanceChange(e) {
        this.setState({ magicItem: { lammieTitle: this.state.magicItem.lammieTitle, lammieText: this.state.magicItem.lammieText, thirdEye: { glance: e.target.value, gaze: this.state.magicItem.thirdEye.gaze } } });
    }

    handleGazeChange(e) {
        this.setState({ magicItem: { lammieTitle: this.state.magicItem.lammieTitle, lammieText: this.state.magicItem.lammieText, thirdEye: { glance: this.state.magicItem.thirdEye.glance, gaze: e.target.value } } });
    }

    handleLeftFontSizeChange(e) {
        this.setState({ fontSize: { left: e.target.value, right: this.state.fontSize.right } });
    }

    handleRightFontSizeChange(e) {
        this.setState({ fontSize: { left: this.state.fontSize.left, right: e.target.value } });
    }
}
