import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

export default class Authorization extends Component {
    constructor(props) {
        super(props);
        this.state = { authorizationResult: "", password: "" };

        this.authorize = this.authorize.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    render() {
        return (
            <div>
                <div>
                    <a href="https://sites.google.com/view/unfinished-tome/home">Link to public google site</a>
                </div>
                <br />
                <Form.Group className="mb-3 w-25">
                    <Form.Label
                        htmlFor="password"
                        className="mb-3"
                    >Password</Form.Label>
                    <Form.Control
                        id="password"
                        className="mb-3"
                        type="password"
                        value={this.state.password}
                        onChange={this.handleChange}
                    ></Form.Control>
                    <Button
                        type="button"
                        className="btn btn-default"
                        onClick={this.authorize}
                    >Authorize</Button>
                </Form.Group>
                <div>
                    {this.state.authorizationResult}
                </div>
            </div>
        );
    }

    async authorize() {
        const authorizationRequest = {
            password: this.state.password
        };

        const settings = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(authorizationRequest)
        };

        const response = await fetch("authorization/authorize", settings);
        const data = await response.text();
        this.setState({ authorizationResult: data });
    }

    handleChange(e) {
        this.setState({ password: e.target.value });
    }
}
